<template>
    <page heading="Categories">
        <template v-slot:header-action>
            <v-button :to="{ name: 'dashboard.categories.create' }">
                Create Category
            </v-button>
        </template>

        <table class="table -striped" v-if="categories.length">
            <thead>
            <tr>
                <th>Name</th>
                <th>Album Count</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="category in categories"
                :key="category.id"
            >
                <td>
                    <editable-text
                        v-model="category.name"
                        :editing="editing === category.id"
                        @save="handleSaveCategory({ category })"
                    />
                </td>
                <td>{{ category.albumCount }}</td>
                <td class="actions">
                    <button-group size="small">
                        <v-button theme="info" @click="handleEditCategory({ category })">Edit</v-button>
                        <v-button theme="error" @click="handleDeleteCategory({ category })">Delete</v-button>
                    </button-group>
                </td>
            </tr>
            </tbody>
        </table>
    </page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Page from '../../layouts/Page.vue';
import VButton from '../../components/Action/VButton.vue';
import ButtonGroup from '../../components/Presentation/ButtonGroup.vue';
import EditableText from '../../components/Action/EditableText.vue';
import store from '../../store';

export default {
    name: 'Categories',
    components: {
        EditableText,
        ButtonGroup,
        VButton,
        Page,
    },
    async beforeRouteEnter() {
        await store.dispatch('categories/fetchCategories');
    },
    computed: {
        ...mapState('categories', ['categories']),
    },
    data: () => ({
        editing: null,
    }),
    methods: {
        ...mapActions('categories', ['deleteCategory']),
        async handleDeleteCategory({ category }) {
            // TODO:
            // eslint-disable-next-line
            if (confirm('u sure?')) {
                await this.deleteCategory({ category });
            }
        },
        async handleEditCategory({ category }) {
            if (this.editing === category.id) {
                this.editing = null;
            } else {
                this.editing = category.id;
            }
        },
        async handleSaveCategory({ category }) {
            console.log(category);
        },
    },
};
</script>

<style lang="scss">
.table {
    .actions {
        vertical-align: middle;
    }
}
</style>
