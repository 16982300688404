<template>
    <div class="editable-text">
        <div
            class="editable-text__input-wrap"
             v-if="editing"
        >
            <input
                class="input editable-text__input"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
            >
            <v-button @click="$emit('save')">Save</v-button>
        </div>
        <template v-else>
            {{ modelValue }}
        </template>
    </div>
</template>

<script>
import VButton from './VButton.vue';

export default {
    name: 'EditableText',
    components: { VButton },
    emits: ['update:modelValue', 'save'],
    props: {
        editing: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: String,
            default: '',
        },
    },
    watch: {
        editing(val) {
            if (!val && this.originalValue !== this.modelValue) {
                this.$emit('update:modelValue', this.originalValue);
            }
        },
    },
    mounted() {
        this.originalValue = this.modelValue;
    },
    data: () => ({
        originalValue: '',
    }),
};
</script>

<style lang="scss" scoped>
.editable-text {
    &__input {
        min-height: unset;
        min-width: unset;
        margin: 0;
        width: auto;
        padding: .2rem;
    }
}
</style>
